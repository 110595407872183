export const poolConfigs = {
  "0x896d8a30C32eAd64f2e1195C2C8E0932Be7Dc20B": {
    name: "Chicken Bonds",
    icon: "cb-logo",
    network: "Ethereum",
    blockExplorer: "https://etherscan.io/",
    coin: "LUSD",
    collateral: "ETH",
    apiUrl: "https://api.thegraph.com/subgraphs/name/shmuel-web/bprotocol-chicken-bonds",
    pnlBaseLine: "0",
  },
  "0x00ff66ab8699aafa050ee5ef5041d1503aa0849a": {
    name: "Liquity",
    icon: "Liquity",
    network: "Ethereum",
    blockExplorer: "https://etherscan.io/",
    coin: "LUSD",
    collateral: "ETH",
    apiUrl: "https://api.thegraph.com/subgraphs/name/shmuel-web/bprotocol-liquity",
    pnlBaseLine: "3.11"
  },
  "0xebf8252756268091e523e57d293c0522b8afe66b": {
    name: "Vesta",
    icon: "Vesta",
    network: "Arbitrum",
    blockExplorer: "https://arbiscan.io",
    coin: "VST",
    collateral: "gOHM",
    apiUrl: "https://api.thegraph.com/subgraphs/name/shmuel-web/bprotocol-vesta-gohm",
    pnlBaseLine: "6.79"
  },
  "0x0a30963A461aa4eb4252b5a06525603E49034C41": {
    name: "Vesta",
    icon: "Vesta",
    network: "Arbitrum",
    blockExplorer: "https://arbiscan.io",
    coin: "VST",
    collateral: "renBTC",
    apiUrl: "https://api.thegraph.com/subgraphs/name/shmuel-web/bprotocol-vesta-renbtc",
    pnlBaseLine: "9.70"
  },
  "0x12c60b3170fb43e6a8f8ba2d843621c19324329e": {
    name: "Vesta",
    icon: "Vesta",
    network: "Arbitrum",
    blockExplorer: "https://arbiscan.io",
    coin: "VST",
    collateral: "ETH",
    apiUrl: "https://api.thegraph.com/subgraphs/name/shmuel-web/bprotocol-vesta-eth",
    pnlBaseLine: "6.44"
  },
  "0xaaa": {
    comingSoon: true,
    name: "Hundred",
    icon: "Hundred",
    network: "Arbitrum",
    coin: "USDC",
    collateral: "multiple",
  },    
  "0xbbb": {
    comingSoon: true,
    name: "Hundred",
    icon: "Hundred",
    network: "Arbitrum",
    coin: "DAI",
    collateral: "multiple",
  },  
  // // not working the-graph issue
  // "0x04208f296039f482810B550ae0d68c3E1A5EB719": {
  //   name: "Hundred",
  //   icon: "Hundred",
  //   network: "Arbitrum",
  //   blockExplorer: "https://arbiscan.io",
  //   coin: "multiple",
  //   collateral: "USDC",
  //   apiUrl: "https://api.thegraph.com/subgraphs/name/shmuel-web/bprotocol-hundred-usdc-arbitrum",
  //   pnlBaseLine: "1"
  // },
  "0x6d62d6af9b82cdfa3a7d16601ddbcf8970634d22": {
    name: "Hundred",
    icon: "Hundred",
    network: "Fantom",
    blockExplorer: "https://ftmscan.com/",
    coin: "DAI",
    collateral: "multiple",
    apiUrl: "https://api.thegraph.com/subgraphs/name/shmuel-web/bprotocol-hundred-dai-fantom",
    pnlBaseLine: "17.79",
    noImbalance: true
  },
  "0xedc7905a491ff335685e2f2f1552541705138a3d": {
    name: "Hundred",
    icon: "Hundred",
    network: "Fantom",
    blockExplorer: "https://ftmscan.com/",
    coin: "USDC",
    collateral: "multiple",
    apiUrl: "https://api.thegraph.com/subgraphs/name/shmuel-web/bprotocol-hundred-usdc-fantom",
    pnlBaseLine: "1",
    noImbalance: true,
    decimal: 6
  },  
  "0xfff": {
    comingSoon: true,
    name: "Hundred",
    icon: "Hundred",
    network: "Polygon",
    coin: "USDC",
    collateral: "multiple",
  },  
  "0xggg": {
    comingSoon: true,
    name: "Hundred",
    icon: "Hundred",
    network: "Polygon",
    coin: "USDT",
    collateral: "multiple",
  },  
  "0xrrr": {
    comingSoon: true,
    name: "Hundred",
    icon: "Hundred",
    network: "Polygon",
    coin: "DAI",
    collateral: "multiple",
  },  
  "0xttt": {
    comingSoon: true,
    name: "Hundred",
    icon: "Hundred",
    network: "Polygon",
    coin: "FRAX",
    collateral: "multiple",
  },
}